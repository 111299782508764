import React from "react"
import { Helmet } from "react-helmet"

const EmailSignature = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <style>
        {`
          img{
            display: inline-block;
          }
        `}
      </style>
      <table
        style={{ backgroundColor: "#0A1E46", width: "660px" }}
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td style={{ verticalAlign: "middle" }}>
              <img
                src="https://synkli.com.au/images/email-signature/logo-with-bg.png"
                width="210px"
                alt="Synkli Logo"
              />
            </td>
            <td>
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                <span style={{ color: "#B695F8" }}>Kaleem</span> Ullah
              </div>
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "white",
                }}
              >
                Founder & CEO
                <img
                  src="https://synkli.com.au/images/email-signature/name-underline.png"
                  style={{ display: "block", marginTop: "3px" }}
                  alt="line"
                />
              </div>
              <br />
              <div>
                <img
                  src="https://synkli.com.au/images/email-signature/phone-icon.png"
                  width="17px"
                  alt="Phone"
                />
                <a
                  href="tel:+61430858990"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "12px",
                    fontWeight: 500,
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                >
                  +61 430 858 990
                </a>
              </div>
              <div>
                <img
                  src="https://synkli.com.au/images/email-signature/email-icon.png"
                  width="17px"
                  alt="Email"
                />
                <a
                  href="mailto:kaleem@synkli.com.au"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "12px",
                    fontWeight: 500,
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                >
                  kaleem@synkli.com.au
                </a>
              </div>
              <div>
                <img
                  src="https://synkli.com.au/images/email-signature/globe-icon.png"
                  width="17px"
                  alt="Website"
                />
                <a
                  href="https://www.synkli.com.au/"
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "12px",
                    fontWeight: 500,
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                >
                  www.synkli.com.au
                </a>
              </div>
            </td>
            <td>
              <table style={{ width: "220px" }} align="right">
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "12px",
                          fontWeight: 600,
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <span style={{ color: "#B695F8" }}>Scan</span> &
                        Download Now!
                      </div>
                    </td>
                    <td>
                      <img
                        src="https://synkli.com.au/images/email-signature/white-arrow-icon.png"
                        width="30px"
                        alt="Arrow"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="https://synkli.com.au/images/email-signature/google-play--qr-code-1.png"
                        width="78px"
                        alt="Google QR"
                      />
                      <a
                        href="https://play.google.com/store/apps/details?id=com.synkli.au&hl=en&pli=1"
                        style={{ display: "block", marginTop: "5px" }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/google-play--icon-2.png"
                          width="78px"
                          alt="Play Store"
                        />
                      </a>
                    </td>
                    <td>
                      <img
                        src="https://synkli.com.au/images/email-signature/appstore--qr-code-1.png"
                        width="78px"
                        alt="Apple QR"
                      />
                      <a
                        href="https://apps.apple.com/au/app/synkli/id6479931845"
                        style={{ display: "block", marginTop: "5px" }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/appstore--icon-1.png"
                          width="78px"
                          alt="App Store"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              colSpan="4"
              style={{
                backgroundColor: "#B695F8",
                padding: "10px 40px 10px 35px",
              }}
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      <img
                        src="https://synkli.com.au/images/email-signature/star-icon.png"
                        width="10px"
                        alt="Star Icon"
                        style={{ marginRight: `3px` }}
                      />
                      Expense & Income Management <br />
                      <img
                        src="https://synkli.com.au/images/email-signature/star-icon.png"
                        width="10px"
                        alt="Star Icon"
                        style={{ marginRight: `3px` }}
                      />
                      Task Management and Reporting
                    </td>
                    <td
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      <img
                        src="https://synkli.com.au/images/email-signature/star-icon.png"
                        width="10px"
                        alt="Star Icon"
                        style={{ marginRight: `3px` }}
                      />
                      Client Management <br />
                      <img
                        src="https://synkli.com.au/images/email-signature/star-icon.png"
                        width="10px"
                        alt="Star Icon"
                        style={{ marginRight: `3px` }}
                      />
                      Connect to Xero
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <a
                        href="https://www.linkedin.com/company/synkli/"
                        style={{ marginRight: `4px` }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/linkedin-icon-1.png"
                          alt="LinkedIn Icon"
                          width="20px"
                        />
                      </a>
                      <a
                        href="https://x.com/Synkli157373"
                        style={{ marginRight: `4px` }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/twitter-icon-1.png"
                          alt="Twitter Icon"
                          width="20px"
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/Synkli.Official/"
                        style={{ marginRight: `4px` }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/facebook-icon-1.png"
                          alt="Facebook Icon"
                          width="20px"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/synkli.official/"
                        style={{ marginRight: `4px` }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/instagram-icon-1.png"
                          alt="Instagram Icon"
                          width="20px"
                        />
                      </a>
                      <a
                        href="https://www.pinterest.com/synkli/"
                        style={{ marginRight: `4px` }}
                      >
                        <img
                          src="https://synkli.com.au/images/email-signature/pinterest-icon-1.png"
                          alt="Pinterest Icon"
                          width="20px"
                        />
                      </a>
                      <a href="https://www.youtube.com/@Synkli.Official">
                        <img
                          src="https://synkli.com.au/images/email-signature/youtube-icon-1.png"
                          alt="Youtube Icon"
                          width="20px"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default EmailSignature
